
.footer {
  position:absolute;
  height:2.5rem;
  bottom:0;
  left:0;
  width:100%;
  padding-bottom:6rem;
}

.center-content {
  justify-content: center;
}
