.card-width {
  width: 100%;
  margin: 0;
  @media (min-width: 540px) {
    width: 600px;
  }
}

#content-wrapper {
  padding-bottom: 9rem;
}

@media (min-height: 1020) {
  #content-wrapper {
    padding-bottom: 2.5rem;
  }
}

.textbox-width {
  width: 100%;
  @media (min-width: 540px) {
    width: 325px;
  }
}
